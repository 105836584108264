import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

import router from '@/router'
import store from '@/store'

import OpticEntity from '../../../entities/optic.entity'
import AddressModel from '../../../models/address.model'
import UserEntity from '../../../entities/user.entity'
import RoleEnum from '../../../utils/roles'
import UserController from '../../../controllers/user.controller'
import SubscriptionController from '../../../controllers/subscriptions.controller'
import CheckoutSubscriptionSession from '../../../entities/checkout-session-subscription.entity'

export default function useOpticSign() {
  const toast = useToast()

  const loading = ref(false)
  const loadingUser = ref(false)

  const opticData = ref(new OpticEntity({ address: new AddressModel() }))

  /**
   * Gets an user from the database.
   *
   * @param {string} uid the user uid.
   */
  const getUser = async (uid) => {
    loadingUser.value = true

    try {
      /**
       * @type {UserEntity}
       */
      const user = await store.dispatch('user/fetchOneUser', uid)

      if (!user) {
        return showError('User not found')
      }

      if (user.role !== RoleEnum.OPTIC_ADMIN) {
        return showError('The user is not suitable for registering an optic')
      }

      if (user.adminDocRef) {
        showError('This user already has an optic')
        router.push('/admin')
      }

      opticData.value.adminId = user.id
      opticData.value.userEmail = user.email

      return user
    } catch (e) {
      showError('Error fetching user')
    } finally {
      loadingUser.value = false
    }
  }

  /**
   * Submits the optic, which creates it into the database and links to the user.
   */
  const submit = async () => {
    loading.value = true

    let opticId = ''

    try {
      /**
       * @type {{data: OpticEntity}}
       */
      const optic = await store.dispatch('optic/setOptic', {
        data: opticData.value,
      })
      opticId = optic.data.id

      const user = await getUser(optic.data.adminId)
      user.adminDocRef = `optics/${optic.data.id}`

      await new UserController().update(user.id, user)
      store.dispatch('auth/setUser', user)

      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.opticSign.success'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      const customer = await store.dispatch('user/fetchOneCustomer', user.id)

      const sessionResult =
        await new SubscriptionController().subscribeToProduct(
          new CheckoutSubscriptionSession({
            customer: customer.stripeId,
            lineItems: [{ price: router.currentRoute.query.prid, quantity: 1 }],
            successUrl: `${window.location.origin}/admin`,
            cancelUrl: `${window.location.origin}/cancel-checkout?uid=${user.id}&oid=${opticId}`,
            subscriptionData: {
              trial_period_days: 7,
            },
          }),
        )
      console.log(sessionResult)
      window.location.href = sessionResult.data.data.url
    } catch (e) {
      console.log(e)
      showError('Error registering optic')

      if (opticId) {
        store.dispatch('optic/hardDeleteOptic', `optics/${opticId}`)
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * Shows an error as a danger toast.
   *
   * @param {string} message the error message.
   */
  const showError = (message) => {
    toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  }

  return {
    loading,
    loadingUser,
    opticData,
    getUser,
    showError,
    submit,
  }
}
