<template>
  <b-overlay :show="loading">
    <div class="auth-wrapper auth-v1 px-2">
      <b-row class="auth-inner py-2" align-h="center">
        <b-card>
          <b-col cols="12">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              {{ $t('opticForm.sectionTitle') }}
            </b-card-title>

            <b-card-text class="mb-2">
              {{ $t('opticForm.sectionSubtitle') }}
            </b-card-text>

            <validation-observer
              ref="registerValidation"
              #default="{ invalid }"
            >
              <b-form class="auth-form mt-2" @submit.prevent>
                <validation-provider
                  v-if="!$route.query.prid"
                  #default="{ errors }"
                  :name="$t('admin.labs.form.subscriptionPlan')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('admin.labs.form.subscriptionPlan')"
                    label-for="subscription"
                  >
                    <v-select
                      v-model="plan"
                      label="description"
                      :placeholder="$t('admin.labs.form.subscriptionPlan')"
                      :options="plans"
                      :clearable="false"
                    />
                  </b-form-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <!-- fantasy name -->
                <validation-provider
                  #default="{ errors }"
                  :name="$t('opticForm.sectiomField1')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('opticForm.sectiomField1')"
                    label-for="fantasyName"
                  >
                    <b-form-input
                      v-model="opticData.nameFantasy"
                      class="form-control"
                      :placeholder="$t('opticForm.sectiomPlaceholder1')"
                      :class="{ 'is-invalid': errors.length }"
                      :state="errors.length ? false : null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <!-- registered name -->
                <validation-provider
                  #default="{ errors }"
                  :name="$t('opticForm.sectiomField2')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('opticForm.sectiomField2')"
                    label-for="registeredName"
                  >
                    <b-form-input
                      v-model="opticData.nameRegistered"
                      class="form-control"
                      :placeholder="$t('opticForm.sectiomPlaceholder2')"
                      :class="{ 'is-invalid': errors.length }"
                      :state="errors.length ? false : null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <!-- Registration number -->
                <validation-provider
                  #default="{ errors }"
                  :name="$t('opticForm.sectionField8')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('opticForm.sectionField8')"
                    label-for="registrationNumber"
                  >
                    <cleave
                      v-model="opticData.cnpj"
                      class="form-control"
                      :options="cnpjDelimiter"
                      :placeholder="$t('opticForm.sectionPlaceholder8')"
                      :class="{ 'is-invalid': errors.length }"
                      :state="errors.length ? false : null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <b-row>
                  <b-col cols="8">
                    <!-- address line 1 -->
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('opticForm.sectiomField3')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('opticForm.sectiomField3')"
                        label-for="addressLine1"
                      >
                        <b-form-input
                          v-model="opticData.address.line1"
                          class="form-control"
                          :placeholder="$t('opticForm.sectiomPlaceholder3')"
                          :class="{ 'is-invalid': errors.length }"
                          :state="errors.length ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="4">
                    <!-- number -->
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('opticForm.sectiomField7')"
                      rules="required|integer|positive"
                    >
                      <b-form-group
                        :label="$t('opticForm.sectiomField7')"
                        label-for="number"
                      >
                        <b-form-input
                          v-model="opticData.address.number"
                          class="form-control"
                          :placeholder="$t('opticForm.sectiomPlaceholder7')"
                          :class="{ 'is-invalid': errors.length }"
                          :state="errors.length ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- zip code -->
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('opticForm.sectiomField6')"
                      rules="required|integer|positive"
                    >
                      <b-form-group
                        :label="$t('opticForm.sectiomField6')"
                        label-for="zipCode"
                      >
                        <b-form-input
                          v-model="opticData.address.zipCode"
                          class="form-control"
                          :placeholder="$t('opticForm.sectiomPlaceholder6')"
                          :class="{ 'is-invalid': errors.length }"
                          :state="errors.length ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="6" lg="6" sm="6">
                    <!-- address line 2 -->
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('opticForm.sectiomPlaceholder4')"
                    >
                      <b-form-group
                        :label="$t('opticForm.sectiomPlaceholder4')"
                        label-for="addressLine2"
                      >
                        <b-form-input
                          v-model="opticData.address.line2"
                          class="form-control"
                          :placeholder="$t('opticForm.sectiomPlaceholder4')"
                          :class="{ 'is-invalid': errors.length }"
                          :state="errors.length ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- country -->
                <validation-provider
                  #default="{ errors }"
                  :name="$t('opticForm.sectiomField5')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('opticForm.sectiomField5')"
                    label-for="country"
                  >
                    <country-select
                      class="country-form"
                      v-model="opticData.address.country"
                      topCountry="BR"
                      :usei18n="false"
                      :placeholder="$t('profile.editUserForm.country')"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <!-- submit buttons -->
                <b-button
                  block
                  :disabled="!opticData.adminId || invalid"
                  :variant="
                    opticData.adminId && !invalid ? 'primary' : 'secondary'
                  "
                  @click="save()"
                >
                  <b-spinner v-if="loading" small />

                  <span v-else>{{ $t('opticForm.sectionFeature') }}</span>
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-card>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'

import { onUnmounted } from '@vue/composition-api'

import { required, integer, positive } from '@validations'
import router from '@/router'
import store from '@/store'

import { cnpjDelimiter } from '@/utils/formatters'

import useOpticSign from './useOpticSign'
import userStoreModule from '../../admin/users/userStoreModule'
import opticStoreModule from './opticStoreModule'

import planStoreModule from '@/views/admin/plans/planStoreModule'
import vueCountryRegionSelect from 'vue-country-region-select'

export default {
  name: 'OpticSign',

  components: {
    BButton,
    BCard,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BSpinner,
    BOverlay,
    Cleave,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      integer,
      positive,
      cnpjDelimiter,
      plan: ''
    }
  },

  methods: {
    async save() {
      if(!this.$route.query.prid) {
        this.$router.replace({query: {uid: this.$route.query.uid, prid: this.plan.id}})
      }
      await this.submit()
    }
  },

  computed: {
    plans() {
      let product = 'optic'

      const entityPlan = this.$store.state.plan.plans.find(
        (el) => el.metadata && el.metadata.type === product,
      )
      return (
        entityPlan?.prices
          .filter((el) => el.active == true)
          .sort((a, b) => a.description.localeCompare(b.description)) ?? []
      )
    },
  },

  mounted() {
    const USER_APP_STORE_MODULE_NAME = 'user'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'
    const PLAN_APP_STORE_MODULE_NAME = 'plan'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }

    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }

    const { loading, opticData, getUser, showError, submit } = useOpticSign()

    const uid = router.currentRoute.query.uid

    if (!uid) {
      showError('Invalid link')
      router.push('/')
    }

    getUser(uid)

    store.dispatch('plan/fetchPlans')

    return { loading, opticData, submit }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-auth.scss';

.country-form {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;

  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s;
}
</style>
