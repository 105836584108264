var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('b-row',{staticClass:"auth-inner py-2",attrs:{"align-h":"center"}},[_c('b-card',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('opticForm.sectionTitle'))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('opticForm.sectionSubtitle'))+" ")]),_c('validation-observer',{ref:"registerValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[(!_vm.$route.query.prid)?_c('validation-provider',{attrs:{"name":_vm.$t('admin.labs.form.subscriptionPlan'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('admin.labs.form.subscriptionPlan'),"label-for":"subscription"}},[_c('v-select',{attrs:{"label":"description","placeholder":_vm.$t('admin.labs.form.subscriptionPlan'),"options":_vm.plans,"clearable":false},model:{value:(_vm.plan),callback:function ($$v) {_vm.plan=$$v},expression:"plan"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('opticForm.sectiomField1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('opticForm.sectiomField1'),"label-for":"fantasyName"}},[_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":_vm.$t('opticForm.sectiomPlaceholder1'),"state":errors.length ? false : null},model:{value:(_vm.opticData.nameFantasy),callback:function ($$v) {_vm.$set(_vm.opticData, "nameFantasy", $$v)},expression:"opticData.nameFantasy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('opticForm.sectiomField2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('opticForm.sectiomField2'),"label-for":"registeredName"}},[_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":_vm.$t('opticForm.sectiomPlaceholder2'),"state":errors.length ? false : null},model:{value:(_vm.opticData.nameRegistered),callback:function ($$v) {_vm.$set(_vm.opticData, "nameRegistered", $$v)},expression:"opticData.nameRegistered"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('opticForm.sectionField8'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('opticForm.sectionField8'),"label-for":"registrationNumber"}},[_c('cleave',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.cnpjDelimiter,"placeholder":_vm.$t('opticForm.sectionPlaceholder8'),"state":errors.length ? false : null},model:{value:(_vm.opticData.cnpj),callback:function ($$v) {_vm.$set(_vm.opticData, "cnpj", $$v)},expression:"opticData.cnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('opticForm.sectiomField3'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('opticForm.sectiomField3'),"label-for":"addressLine1"}},[_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":_vm.$t('opticForm.sectiomPlaceholder3'),"state":errors.length ? false : null},model:{value:(_vm.opticData.address.line1),callback:function ($$v) {_vm.$set(_vm.opticData.address, "line1", $$v)},expression:"opticData.address.line1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('opticForm.sectiomField7'),"rules":"required|integer|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('opticForm.sectiomField7'),"label-for":"number"}},[_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":_vm.$t('opticForm.sectiomPlaceholder7'),"state":errors.length ? false : null},model:{value:(_vm.opticData.address.number),callback:function ($$v) {_vm.$set(_vm.opticData.address, "number", $$v)},expression:"opticData.address.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('opticForm.sectiomField6'),"rules":"required|integer|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('opticForm.sectiomField6'),"label-for":"zipCode"}},[_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":_vm.$t('opticForm.sectiomPlaceholder6'),"state":errors.length ? false : null},model:{value:(_vm.opticData.address.zipCode),callback:function ($$v) {_vm.$set(_vm.opticData.address, "zipCode", $$v)},expression:"opticData.address.zipCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('opticForm.sectiomPlaceholder4')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('opticForm.sectiomPlaceholder4'),"label-for":"addressLine2"}},[_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":_vm.$t('opticForm.sectiomPlaceholder4'),"state":errors.length ? false : null},model:{value:(_vm.opticData.address.line2),callback:function ($$v) {_vm.$set(_vm.opticData.address, "line2", $$v)},expression:"opticData.address.line2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('opticForm.sectiomField5'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('opticForm.sectiomField5'),"label-for":"country"}},[_c('country-select',{staticClass:"country-form",attrs:{"topCountry":"BR","usei18n":false,"placeholder":_vm.$t('profile.editUserForm.country')},model:{value:(_vm.opticData.address.country),callback:function ($$v) {_vm.$set(_vm.opticData.address, "country", $$v)},expression:"opticData.address.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{attrs:{"block":"","disabled":!_vm.opticData.adminId || invalid,"variant":_vm.opticData.adminId && !invalid ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.save()}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v(_vm._s(_vm.$t('opticForm.sectionFeature')))])],1)],1)]}}])})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }